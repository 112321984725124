import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import RouterWrapper from './wrapper.routes';
import RequireAuth from 'components/RequireAuth';

import { ROUTES, ROUTES_INSIDE } from './list.routes';
import { ThemeProvider } from 'styled-components';
import colors from 'utils/colors';
import { useTheme } from 'contexts/ThemeContext';
import Loading from 'components/Loading';

function Paths() {
  const { theme } = useTheme();

  return (
    <Suspense fallback={<Loading status />}>
      <ThemeProvider theme={theme ?? colors}>
        <Routes>
          {ROUTES.map((route) => {
            const Component = route.element;

            return (
              <Route
                key={route.path}
                path={route.path}
                element={<RouterWrapper children={<Component />} />}
              />
            );
          })}

          <Route element={<RequireAuth />}>
            {ROUTES_INSIDE.map((route) => {
              const Component = route.element;

              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<RouterWrapper dashboard children={<Component />} />}
                />
              );
            })}
          </Route>

          {/*<Route path="*" element={<NotFound />} />*/}
        </Routes>
      </ThemeProvider>
    </Suspense>
  );
}

export default Paths;
