import styled from 'styled-components';

export const Imagem = styled.img`
  width: 80%;
  max-height: 50px;
  object-fit: cover;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
