import { Col, Container } from 'react-bootstrap';

import styled from 'styled-components';
import metrics from 'utils/theme';

export const CustomContainer = styled(Container)`
  background: ${({ theme }) => theme.gray};
  min-height: 100vh;
`;

export const ImageBackground = styled(Col)`
  padding: 0;
  margin: 0;
`;

export const Imagem = styled.img`
  min-height: 100%;
  max-height: 100vh;
  object-fit: cover;
`;

export const CustomCol = styled(Col)`
  padding: 144px 120px 60px 120px;

  @media (max-width: 820px) {
    padding: ${metrics.spacings.smedium};
  }
`;

export const ContainerAside = styled(Col)`
  display: flex;
  padding: 0px;
`;

export const ContainerHeader = styled(Col)`
  padding: 0px;
`;

export const CustomContainerDashboard = styled(Container)`
  height: 100vh !important;
  overflow: hidden;
`;

export const CustomColOverflow = styled(Col)`
  overflow: auto;
`;
