import whiteLogo from 'assets/img/whiteLogo.png';
import { useTheme } from 'contexts/ThemeContext';
import { Container, Imagem } from './styles';

function WhiteLogo() {
  const { whiteLabel } = useTheme();
  return (
    <Container>
      <Imagem
        src={whiteLabel.urlIconeMenu ?? whiteLogo}
        className="img-fluid"
        alt={`Logotipo ${whiteLabel.nomeFantasia ?? 'Analytica Ensino'}`}
      />
    </Container>
  );
}

export default WhiteLogo;
