import { createContext, useContext, useEffect, useState } from 'react';
import colors from 'utils/colors';

import { useNavigate } from 'react-router-dom';
import { api } from 'services/api';

interface ImagesWhiteLabel {
  urlIconeFavicon?: string;
  urlIconeMenu?: string;
  urlImagemLogin?: string;
  urlLogoMenu?: string;
  nomeFantasia?: string;
  path?: string;
  instituicaoId?: string;
  corSecundaria?: string;
  habilitarMetas?: boolean;
  habilitarSimulados?: boolean;
  habilitarGraficos?: boolean;
  habilitarProfessoresAdicionarUsuarios?: boolean;
  habilitarTickets?: boolean;
  habilitarCalendario?: boolean;
  dashboardSimplificado?: boolean;
  conteudoClassico?: boolean;
  downloadConteudo?: boolean;
  habilitarRanking?: boolean;
  habilitarAulaAoVivo?: boolean;
}
interface ThemeContextType {
  theme: {
    primaryBrand500: string;
    secondaryBrand500: string;
    tertiaryBrand500: string;
    error500Alpha: string;
    errorDefault: string;
    error500: string;
    error300: string;
    error200: string;
    error100: string;
    warning500: string;
    warning300: string;
    warning100: string;
    warning50: string;
    success500Alpha: string;
    success500: string;
    success300: string;
    success100: string;
    success50: string;
    white: string;
    black: string;
    gray: string;
    darkgray: string;
    darkgray100: string;
    darkgray200: string;
    darkgray300: string;
    shadow: string;
    listRaking: string;
    blue: string;
    darkblue: string;
    darkblue300: string;
    starYellow: string;
  };
  getTheme: (path: string) => Promise<string | undefined>;
  clearTheme: () => void;
  whiteLabel: ImagesWhiteLabel;
}

interface IWhiteLabel {
  instituicaoId: string;
  nomeFantasia: string;
  corPrimaria: string;
  corSecundaria: string;
  corTerciaria: string;
  urlIconeFavicon: string;
  urlIconeMenu: string;
  urlImagemLogin: string;
  urlLogoMenu: string;
}

interface ResponseData {
  statusCode: number;
  message: string;
  whiteLabel: IWhiteLabel;
}

export const ThemeContext = createContext({} as ThemeContextType);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState(colors);
  const [whiteLabel, setWhiteLabel] = useState({} as ImagesWhiteLabel);
  const navigate = useNavigate();

  const clearTheme = async () => {
    setTheme(colors);
    setWhiteLabel({});
    localStorage.removeItem('whiteLabelAnalytica');
  };

  const getTheme = async (path: string): Promise<string | undefined> => {
    try {
      const response: ResponseData = await api.get(
        '/instituicao/white-label/detalhes',
        {
          params: {
            name: path,
          },
        }
      );

      const themeData = {
        ...theme,
        primaryBrand500: response.whiteLabel.corPrimaria,
        secondaryBrand500: response.whiteLabel.corSecundaria,
        tertiaryBrand500: response.whiteLabel.corTerciaria,
      };

      const whiteLabelData = {
        ...response?.whiteLabel,
        path: path,
      };

      setTheme(themeData);
      setWhiteLabel(whiteLabelData);

      const favicon = document.getElementById('favicon');
      if (favicon && response.whiteLabel.urlIconeFavicon) {
        favicon.setAttribute('href', response.whiteLabel.urlIconeFavicon);
      }

      localStorage.setItem(
        'whiteLabelAnalytica',
        JSON.stringify({
          whiteLabel: whiteLabelData,
          theme: themeData,
        })
      );

      return response.whiteLabel.instituicaoId;
    } catch {
      clearTheme();
      localStorage.removeItem('whiteLabelAnalytica');
      navigate(`/${path}/404`, { state: { notFound: true } });
    }
  };

  useEffect(() => {
    const getWhiteLabel = localStorage.getItem('whiteLabelAnalytica');
    if (getWhiteLabel) {
      const wlStorage = JSON.parse(getWhiteLabel);
      setWhiteLabel(wlStorage?.whiteLabel);
      setTheme(wlStorage?.theme);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, whiteLabel, getTheme, clearTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
