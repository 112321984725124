import { useTheme } from 'contexts/ThemeContext';
import {
  Container,
  CustomButton,
  IconContainer,
  TextContainer,
} from './styles';

interface ButtonProps {
  text: string;
  type: 'button' | 'submit';
  outline?: boolean;
  children?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  width?: string;
  minWidth?: string;
  borderRadius?: string;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  variant?: 'primary' | 'danger' | 'success';
  disabled?: boolean;
  className?: string;
  justifyStyle?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

function InsideButton({
  text,
  type,
  outline = false,
  onClick,
  style,
  width,
  minWidth,
  borderRadius,
  icon,
  iconRight,
  variant = 'primary',
  disabled = false,
  className = '',
  justifyStyle = 'center',
}: ButtonProps) {
  const { theme } = useTheme();

  const colorSelect = (variant: string) => {
    switch (variant) {
      case 'primary':
        return theme.primaryBrand500;
      case 'danger':
        return theme.error500;
      case 'success':
        return theme.success500;
      default:
        return theme.primaryBrand500;
    }
  };

  return (
    <CustomButton
      width={width}
      minWidth={minWidth}
      borderRadius={borderRadius}
      style={style}
      outline={outline}
      type={type}
      white={theme.white}
      secondary={theme.secondaryBrand500}
      onClick={onClick}
      className={'btn-inside ' + className}
      background={colorSelect(variant)}
      disabled={disabled}
    >
      <Container justifyStyle={justifyStyle}>
        {icon && <IconContainer>{icon}</IconContainer>}
        <TextContainer>{text}</TextContainer>
        {iconRight && <IconContainer>{iconRight}</IconContainer>}
      </Container>
    </CustomButton>
  );
}

export default InsideButton;
