import { AuthProvider } from 'contexts/AuthContext';
import { ModalProvider } from 'contexts/ModalContext';
import { ControlMediaProvider } from './ControlMediaContext';
import { InterceptorProvider } from './InterceptorContext';
import { PaginationProvider } from './PaginationContext';
import { SpinnerProvider } from './SpinnerContext';
import { ThemeProvider } from './ThemeContext';
import { TimerProvider } from './TimerContext';
import { TitleProvider } from './TitleContext';

const Contexts = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider>
    <SpinnerProvider>
      <TitleProvider>
        <TimerProvider>
          <PaginationProvider>
            <ModalProvider>
              <AuthProvider>
                <InterceptorProvider>
                  <ControlMediaProvider>{children}</ControlMediaProvider>
                </InterceptorProvider>
              </AuthProvider>
            </ModalProvider>
          </PaginationProvider>
        </TimerProvider>
      </TitleProvider>
    </SpinnerProvider>
  </ThemeProvider>
);

export default Contexts;
