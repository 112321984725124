import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

function Api(): AxiosInstance {
  const token = localStorage.getItem('@token:analytica');

  const api = axios.create({
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  api.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    async (error: AxiosError) => {
      if (error.response?.status === 401) {
        const refreshToken = localStorage.getItem('@refresh-token:analytica');
        const whiteLabel = localStorage.getItem('whiteLabelAnalytica');

        if (refreshToken) {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/auth/refresh-token/${refreshToken}`
            );

            if (response?.data?.token && response?.data?.refresh_token) {
              localStorage.setItem(
                '@refresh-token:analytica',
                response.data.refresh_token
              );

              localStorage.setItem('@token:analytica', response.data.token);

              api.defaults.headers.common['Authorization'] =
                `Bearer ${response.data.token}`;

              if (error.config) {
                error.config.headers['Authorization'] =
                  `Bearer ${response.data.token}`;

                return api.request(error.config);
              }
            }

            if (whiteLabel) {
              const wlStorage = JSON.parse(whiteLabel ?? '');
              window.location.href = `/${wlStorage?.whiteLabel?.path}`;
              return;
            }

            return (window.location.href = '/');
          } catch {
            if (whiteLabel) {
              const wlStorage = JSON.parse(whiteLabel ?? '');
              window.location.href = `/${wlStorage?.whiteLabel?.path}`;
              return;
            }

            return (window.location.href = '/');
          }
        }

        if (whiteLabel) {
          const wlStorage = JSON.parse(whiteLabel ?? '');
          window.location.href = `/${wlStorage?.whiteLabel?.path}`;
          return;
        }

        return (window.location.href = '/');
      }

      throw error?.response;
    }
  );

  return api;
}

export const api = Api();
