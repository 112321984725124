import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface TitleContextType {
  setTitle: (title: string) => void;
  setSubtitle: (subtitle: string) => void;
  setPathnameTitle: (path: string) => void;
  title: string;
  subtitle: string;
}

export const TitleContext = createContext({} as TitleContextType);

export const TitleProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [pathnameTitle, setPathnameTitle] = useState('');
  const location = useLocation();
  useEffect(() => {
    if (pathnameTitle !== location.pathname) {
      setTitle('');
      setSubtitle('');
    }
  }, [location.pathname, pathnameTitle]);

  return (
    <TitleContext.Provider
      value={{ title, setTitle, setSubtitle, subtitle, setPathnameTitle }}
    >
      {children}
    </TitleContext.Provider>
  );
};

export const useTitle = () => {
  return useContext(TitleContext);
};
