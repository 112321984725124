import { CustomButton } from './style';

interface ButtonProps {
  text?: string;
  type: 'button' | 'submit';
  outline?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties | undefined;
  disabled?: boolean;
  customGoogleButton?: boolean;
}

function Button({
  text,
  type,
  outline = false,
  onClick,
  style,
  disabled = false,
  children,
  customGoogleButton = false,
}: ButtonProps) {
  return (
    <CustomButton
      outline={outline}
      type={type}
      onClick={onClick}
      style={style}
      disabled={disabled}
      customGoogleButton={customGoogleButton}
    >
      {text}
      {children}
    </CustomButton>
  );
}

export default Button;
