import InsideButton from 'components/Button/InsideButton';
import Button from 'components/Button/OutsideButton';
import { ModalTypes, useModal } from 'contexts/ModalContext';
import {
  CustomModal,
  CustomModalHeader,
  CustomModalTitle,
  CustomModalBody,
  ModalFooter,
  Message,
} from './styles';

function DefaultModal({
  handleAction,
  title,
  show,
  size,
  component,
  message,
  isInsideButton,
  buttonText,
}: ModalTypes) {
  const { setModal } = useModal();

  const onHide = () => {
    setModal({
      show: false,
    });
  };

  const handleClick = () => {
    if (handleAction) {
      handleAction();
    }

    onHide();
  };

  return (
    <CustomModal centered show={show} size={size ?? undefined}>
      <CustomModalHeader closeButton onHide={onHide}>
        <CustomModalTitle boldFont>{title}</CustomModalTitle>
      </CustomModalHeader>
      {component ? (
        <>
          <CustomModalBody>{component}</CustomModalBody>
        </>
      ) : (
        <>
          <CustomModalBody>
            <Message>{message}</Message>
          </CustomModalBody>
          <ModalFooter className="d-grid gap-2">
            {isInsideButton ? (
              <>
                <InsideButton
                  onClick={handleClick}
                  text={buttonText ?? ''}
                  type="button"
                />
              </>
            ) : (
              <>
                <Button
                  onClick={handleClick}
                  text={buttonText ?? ''}
                  type="button"
                />
              </>
            )}
          </ModalFooter>
        </>
      )}
    </CustomModal>
  );
}

export default DefaultModal;
