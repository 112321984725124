import NotificationBell from 'components/NotificationBell';
import ProfileMenu from 'components/ProfileMenu';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { MdMenu } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import links from 'utils/links';

import { useAuth } from 'contexts/AuthContext';
import { useTheme } from 'contexts/ThemeContext';
import { useTitle } from 'contexts/TitleContext';
import { ROUTES_INSIDE } from 'routes/list.routes';
import {
  Collapse,
  ContainerLink,
  ContainerNav,
  ContainerProfile,
  ContainerSubmenu,
  ContainerTitle,
  CustomLink,
  CustomLinkSubmenu,
  CustomNavbar,
  ListItemSubmenu,
  Subtitle,
  Title,
  Toggle,
} from './styles';

interface SubmenuProps {
  path?: string;
  active?: boolean;
}

function Header() {
  const { pathname } = useLocation();
  const { user, profile } = useAuth();
  const { title, subtitle } = useTitle();
  const { theme, whiteLabel } = useTheme();

  const [showContainerLink, setShowContainerLink] = useState<boolean>(false);
  const [submenu, setSubmenu] = useState<SubmenuProps[]>([]);
  const [getLinks, setLinks] = useState(links);

  const profileName = useMemo(() => {
    const arrayProfile = user?.profiles.map((profile) => profile.name);
    if (arrayProfile) {
      return arrayProfile[0];
    }
    return '';
  }, [user]);

  const titleHeader = useMemo(() => {
    const routeInside = ROUTES_INSIDE.find((route) =>
      route.path.includes(pathname)
    );
    if (routeInside?.title === 'Perfil do') {
      return `${routeInside.title} ${profileName}`;
    }

    if (title.length === 0) {
      return routeInside?.title;
    }

    if (title !== '') {
      return title;
    }
  }, [ROUTES_INSIDE, pathname, title]);

  const subtitleHeader = useMemo(() => {
    if (subtitle !== '') {
      return subtitle;
    }
    return undefined;
  }, [subtitle]);

  const handleClick = () => {
    setShowContainerLink(!showContainerLink);
  };

  useEffect(() => {
    setShowContainerLink(false);
  }, [pathname]);

  useEffect(() => {
    const getLink = getLinks.map((link) => {
      return {
        path: link.path,
        active: false,
        disabled: link.disabled,
      };
    });

    setSubmenu(getLink);
  }, [getLinks]);

  useEffect(() => {
    const pathsToRemove: string[] = [];
    if (!whiteLabel.habilitarAulaAoVivo) {
      pathsToRemove.push('/aluno/aula-ao-vivo', '/professor/aula-ao-vivo');
    }
    if (!whiteLabel.habilitarMetas) {
      pathsToRemove.push('/metas', '/plano');
    }
    if (!whiteLabel.habilitarCalendario) {
      pathsToRemove.push('/agendar-atividade');
    }
    if (
      profile === 'TEACHER' &&
      !whiteLabel.habilitarProfessoresAdicionarUsuarios
    ) {
      pathsToRemove.push('/clientes');
    }
    if (!whiteLabel.habilitarGraficos) {
      pathsToRemove.push('/meus-graficos');
    }
    if (!whiteLabel.habilitarRanking) {
      pathsToRemove.push('/ranking');
    }
    if (!whiteLabel.habilitarTickets) {
      pathsToRemove.push('/ticket/meus-tickets', '/ticket/geral');
    }
    const filterLinks = getLinks.filter(
      (link) => !pathsToRemove.includes(link.path)
    );

    setLinks([...filterLinks]);
  }, []);

  const toggleLink = (link: string) => {
    const holdLinks = [...submenu];

    const index = holdLinks.findIndex((item) => item.path === link);
    holdLinks[index].active = !holdLinks[index].active;

    setSubmenu(holdLinks);
  };

  return (
    <CustomNavbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      typeProfile={profileName}
    >
      <ContainerNav>
        <ContainerTitle>
          {!subtitleHeader ? (
            <Title>{titleHeader}</Title>
          ) : (
            <>
              <Title size="small">{titleHeader}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </>
          )}
        </ContainerTitle>
        <ContainerProfile>
          <NotificationBell />
          <ProfileMenu />
          <Toggle onClick={handleClick}>
            <MdMenu color={theme.white} size={25} />
          </Toggle>
        </ContainerProfile>
      </ContainerNav>
      <Collapse show={showContainerLink}>
        <ContainerLink>
          {getLinks.map((link) => {
            if (profile && link?.profiles?.includes(profile)) {
              if (link.submenu && link.submenu.length) {
                return (
                  <Fragment key={link.path}>
                    <CustomLinkSubmenu
                      key={link.path}
                      onClick={() => toggleLink(link.path)}
                    >
                      {link.name}
                    </CustomLinkSubmenu>
                    <ContainerSubmenu
                      isActive={
                        !!submenu.find((item) => item.path === link.path)
                          ?.active
                      }
                    >
                      {link.submenu.map((subLink) => {
                        if (profile && subLink.profiles.includes(profile)) {
                          return (
                            <p key={subLink.path}>
                              <ListItemSubmenu
                                to={subLink.path}
                                onClick={handleClick}
                              >
                                - {subLink.name}
                              </ListItemSubmenu>
                            </p>
                          );
                        }
                      })}
                    </ContainerSubmenu>
                  </Fragment>
                );
              }

              return (
                <CustomLink
                  key={link.path}
                  to={link.path}
                  onClick={handleClick}
                >
                  {link.name}
                </CustomLink>
              );
            }
          })}
        </ContainerLink>
      </Collapse>
    </CustomNavbar>
  );
}

export default Header;
