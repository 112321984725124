import {
  MdBrush,
  MdCalendarToday,
  MdDashboard,
  MdEventNote,
  MdGroupAdd,
  MdInsertChartOutlined,
  MdListAlt,
  MdOutlineArticle,
  MdOutlineForum,
  MdOutlineHelp,
  MdOutlineMenuBook,
  MdRule,
  MdSettings,
  MdTableChart,
  MdVideocam,
} from 'react-icons/md';
import PROFILES from './profiles';

const links = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <MdDashboard size={20} />,
    profiles: [
      PROFILES.STUDENT,
      PROFILES.RESPONSIBLE,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    name: 'Conteúdo',
    path: '/conteudo',
    icon: <MdOutlineArticle size={20} />,
    profiles: [
      PROFILES.STUDENT,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
    submenu: [
      {
        name: 'Cadastrar conteúdo ',
        path: '/conteudo/novo',
        profiles: [
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Aulas',
        path: '/conteudo/pesquisar/area-de-conhecimento',
        profiles: [PROFILES.STUDENT],
      },
      {
        name: 'Atividades',
        path: '/conteudo/atividades',
        profiles: [PROFILES.STUDENT],
      },

      {
        name: 'Categorização',
        path: '/conteudo/cadastros',
        profiles: [
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Lista de conteúdo',
        path: '/conteudo/lista',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
    ],
  },
  {
    name: 'Atividades',
    path: '/atividades',
    icon: <MdEventNote size={20} />,
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
    submenu: [
      {
        name: 'Gerador de atividades',
        path: '/atividades/gerador',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Lista de atividades',
        path: '/atividades/lista',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Modelo de atividades',
        path: '/atividades/modelos',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Correção de atividades',
        path: '/atividades/revisao',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
    ],
  },
  {
    name: 'Banco de questões',
    path: '/banco-questoes',
    icon: <MdTableChart size={20} />,
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
    submenu: [
      {
        name: 'Cadastrar questão',
        path: '/banco-questoes/cadastrar',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Revisar questão',
        path: '/banco-questoes/revisar',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
    ],
  },
  {
    name: 'Meus gráficos',
    path: '/meus-graficos',
    icon: <MdInsertChartOutlined size={20} />,
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    name: 'Gráficos',
    path: '/graficos',
    icon: <MdInsertChartOutlined size={20} />,
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    name: 'Dados de acesso',
    path: '/dados-acesso',
    icon: <MdSettings size={20} />,
    profiles: [
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
    submenu: [
      {
        name: 'Dados gerais',
        path: '/dados-acesso/geral',
        profiles: [
          PROFILES.ADMIN,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
        ],
      },
      {
        name: 'Dados por usuário',
        path: '/dados-acesso/usuario',
        profiles: [
          PROFILES.ADMIN,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
        ],
      },
    ],
  },

  {
    name: 'Cadastro de Grupos',
    path: '/cadastro-grupos',
    icon: <MdGroupAdd size={20} />,
    profiles: [PROFILES.ADMIN],
  },
  {
    name: 'Calendário',
    path: '/agendar-atividade',
    icon: <MdCalendarToday size={20} />,
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    name: 'Comunicação',
    path: '/comunicacao',
    icon: <MdOutlineForum size={20} />,
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.STUDENT,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
    submenu: [
      {
        name: 'Avisos',
        path: '/comunicacao/avisos',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Área de dúvidas',
        path: '/comunicacao/area-duvidas',
        profiles: [
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Área de dúvidas',
        path: '/comunicacao/duvidas',
        profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
      },
      {
        name: 'Avisos',
        path: '/comunicacao/estudante-avisos',
        profiles: [PROFILES.STUDENT],
      },
      {
        name: 'Calendário',
        path: '/comunicacao/gestao',
        profiles: [
          PROFILES.ADMIN,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
        ],
      },
    ],
  },
  {
    name: 'Trilha de Aprendizado',
    path: '/metas',
    icon: <MdRule size={20} />,
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
    submenu: [
      {
        name: 'Consultar metas',
        path: '/metas/consultar-metas',
        profiles: [
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.TEACHER,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Gerar metas',
        path: '/metas/gerador-de-metas',
        profiles: [
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.TEACHER,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Acompanhar metas',
        path: '/metas/acompanhar-metas',
        profiles: [
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.TEACHER,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
    ],
  },
  {
    name: 'Boletim',
    path: '/boletim',
    icon: <MdListAlt size={20} />,
    disabled: false,
    profiles: [
      PROFILES.STUDENT,
      PROFILES.RESPONSIBLE,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
    submenu: [
      {
        name: 'Meus Boletins',
        path: '/boletim/meus-boletins',
        profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
      },
      {
        name: 'Gerar Boletins',
        path: '/boletim/gerar-boletins',
        profiles: [
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
        ],
      },
      {
        name: 'Consultar Boletim',
        path: '/boletim/consultar-boletins',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
      {
        name: 'Consultar Boletim dos Alunos',
        path: '/boletim/consultar-boletins-alunos',
        profiles: [
          PROFILES.TEACHER,
          PROFILES.TEACHER_ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.ADMIN,
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
        ],
      },
    ],
  },
  {
    name: 'Simulados',
    path: '/simulados',
    icon: <MdOutlineMenuBook size={20} />,
    profiles: [PROFILES.STUDENT],
    submenu: [
      {
        name: 'Gerar simulado',
        path: '/simulados/gerar-simulado',
        profiles: [PROFILES.STUDENT],
      },
      {
        name: 'Meus simulados',
        path: '/simulados',
        profiles: [PROFILES.STUDENT],
      },
    ],
  },
  {
    name: 'Trilha de Aprendizado',
    path: '/plano',
    icon: <MdRule size={20} />,
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
    submenu: [
      {
        name: 'Consultar Metas',
        path: '/plano/consultar',
        profiles: [PROFILES.STUDENT],
      },
    ],
  },
  {
    name: 'White Label',
    path: '/white-label',
    icon: <MdBrush size={20} />,
    profiles: [PROFILES.SUPORT, PROFILES.ANALYTICA],
    submenu: [
      {
        name: 'White Label',
        path: '/white-label',
        profiles: [PROFILES.SUPORT, PROFILES.ANALYTICA],
      },
      {
        name: 'Consultar',
        path: '/white-label/consultar',
        profiles: [PROFILES.SUPORT, PROFILES.ANALYTICA],
      },
    ],
  },
  {
    name: 'Cliente ADM',
    path: '/clientes',
    icon: <MdBrush size={20} />,
    profiles: [
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.TEACHER,
    ],
    submenu: [
      {
        name: 'Consultar instituição',
        path: '/clientes-instituicao/consultar',
        profiles: [
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
          PROFILES.ADMIN,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
        ],
      },
      {
        name: 'Cadastrar instituição',
        path: '/clientes-instituicao',
        profiles: [PROFILES.SUPORT, PROFILES.ANALYTICA],
      },
      {
        name: 'Consultar usuário',
        path: '/clientes-usuario/consultar',
        profiles: [
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
          PROFILES.TEACHER,
          PROFILES.ADMIN,
        ],
      },
      {
        name: 'Cadastrar usuário',
        path: '/clientes-usuario',
        profiles: [
          PROFILES.SUPORT,
          PROFILES.ANALYTICA,
          PROFILES.TEACHER,
          PROFILES.ADMIN,
          PROFILES.ADMIN_REGIONAL,
          PROFILES.GESTOR_REGIONAL,
        ],
      },
    ],
  },
  {
    name: 'Aula ao vivo',
    path: '/professor/aula-ao-vivo',
    icon: <MdVideocam size={20} />,
    profiles: [PROFILES.TEACHER, PROFILES.TEACHER_ANALYTICA, PROFILES.SUPORT],
  },
  {
    name: 'Aula ao vivo',
    path: '/aluno/aula-ao-vivo',
    icon: <MdVideocam size={20} />,
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    name: 'Suporte',
    path: '/ticket/meus-tickets',
    icon: <MdOutlineHelp size={20} />,
    profiles: [
      PROFILES.STUDENT,
      PROFILES.RESPONSIBLE,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.TEACHER,
    ],
  },
  {
    name: 'Suporte',
    path: '/ticket/geral',
    icon: <MdOutlineHelp size={20} />,
    profiles: [PROFILES.ANALYTICA, PROFILES.TEACHER_ANALYTICA, PROFILES.SUPORT],
  },
];

export default links;
