import Loading from 'components/Loading';
import { createContext, useContext, useState } from 'react';

interface SpinnerContextType {
  loading: boolean;
  setLoading: (b: boolean) => void;
  setActive: (b: boolean) => void;
}

export const SpinnerContext = createContext({} as SpinnerContextType);

export const SpinnerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);

  return (
    <SpinnerContext.Provider value={{ loading, setLoading, setActive }}>
      {children}
      {active && <Loading status={loading} />}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = () => {
  return useContext(SpinnerContext);
};
