const colors = {
  primaryBrand500: '#510DA6',
  secondaryBrand500: '#00C000',
  tertiaryBrand500: '#1E1F24',
  error500Alpha: '#F4433630',
  errorDefault: '#dc3545',
  error500: '#F44336',
  error300: '#E57373',
  error200: '#FFCDD2',
  error100: '#FFEBEE',
  warning500: '#FFC107',
  warning300: '#FFD54F',
  warning100: '#FFECB3',
  warning50: '#FFF8E1',
  success500Alpha: '#4CAF5030',
  success500: '#4CAF50',
  success300: '#81C784',
  success100: '#C8E6C9',
  success50: '#E8F5E9',
  white: '#fff',
  black: '#000',
  gray: '#E7E6EA',
  darkgray: '#616161',
  darkgray100: '#D9D9D9',
  darkgray200: '#8E8C9A',
  darkgray300: '#424242',
  shadow: 'rgb(0, 0, 0, 0.2)',
  listRaking: 'rgba(81, 13, 166, 0.11)',
  blue: '#1877F2',
  darkblue: '#1E2875',
  darkblue300: '#373b5c',
  starYellow: '#FFED4A',
};

export default colors;
