import { createContext, useContext, useState } from 'react';
interface controlerType {
  audio: boolean;
  video: boolean;
}
interface ControlMediaContextType {
  controler: {
    audio: boolean;
    video: boolean;
  };
  setControler: (values: controlerType) => void;
}

export const ControlMediaContext = createContext({} as ControlMediaContextType);

export const ControlMediaProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [controler, setControler] = useState({
    video: false,
    audio: false,
  });

  return (
    <ControlMediaContext.Provider value={{ controler, setControler }}>
      {children}
    </ControlMediaContext.Provider>
  );
};

export const useControlMedia = () => {
  return useContext(ControlMediaContext);
};
