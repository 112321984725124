import { useMemo } from 'react';
import InsideButton from 'components/Button/InsideButton';
import { useModal } from 'contexts/ModalContext';
import { Col, Row } from 'react-bootstrap';
import {
  IconContent,
  MessageWrapper,
  ModalContent,
  TitleModal,
} from './styles';
import check from 'assets/img/check.png';
import { MdBlock } from 'react-icons/md';
import { IoMdWarning } from 'react-icons/io';
import Button from 'components/Button/OutsideButton';
import { useTheme } from 'contexts/ThemeContext';

interface DefaultModalProps {
  title: string;
  message: string;
  icon?: 'success' | 'reprove' | 'error' | 'block' | 'noIcon';
  variant?: 'withOptions' | 'default';
  cancelButton?: {
    text: string;
    onClick?: () => void;
    outline?: boolean;
  };
  confirmButton: {
    text: string;
    onClick?: () => void;
  };
  isInsideButton?: boolean;
}

function DefaultModal(props: DefaultModalProps) {
  const { setModal } = useModal();
  const { theme } = useTheme();

  const selectIcon = useMemo(() => {
    switch (props.icon) {
      case 'success':
        return <img src={check} height={92} style={{ objectFit: 'contain' }} />;
      case 'reprove':
      case 'error':
        return <MdBlock size={64} color={theme.darkgray200} />;
      case 'noIcon':
        return;
      default:
        return <IoMdWarning size={64} color={theme.darkgray200} />;
    }
  }, [props.icon]);

  return (
    <Row className="d-flex flex-column align-items-center">
      <Row>
        <Col>
          {props.icon === 'noIcon' ? (
            <TitleModal className="font-weight-bold text-center mt-5">
              {props.title}
            </TitleModal>
          ) : (
            <TitleModal className="font-weight-bold text-center">
              {props.title}
            </TitleModal>
          )}
        </Col>
      </Row>
      {props.icon === 'success' ? (
        <ModalContent>{selectIcon}</ModalContent>
      ) : (
        <>
          {props.icon === 'noIcon' ? (
            <ModalContent>{selectIcon}</ModalContent>
          ) : (
            <ModalContent>
              <IconContent>{selectIcon}</IconContent>
            </ModalContent>
          )}
        </>
      )}
      <Row className="mb-5">
        {props.icon === 'noIcon' ? (
          <MessageWrapper>
            <p className="text-center mb-5">{props.message}</p>
          </MessageWrapper>
        ) : (
          <>
            {props.icon === 'success' ? (
              <MessageWrapper>
                <p className="text-center mt-5">{props.message}</p>
              </MessageWrapper>
            ) : (
              <MessageWrapper>
                <p className="text-center">{props.message}</p>
              </MessageWrapper>
            )}
          </>
        )}
      </Row>
      <Row>
        {props.variant === 'withOptions' ? (
          <>
            <Col sm={6}>
              <InsideButton
                text={props.cancelButton?.text ?? ''}
                type="button"
                outline={props.cancelButton?.outline ?? true}
                width="100%"
                onClick={() => {
                  setModal({ show: false }),
                    props.cancelButton?.onClick && props.cancelButton.onClick();
                }}
                style={{ border: `1px solid ${theme.primaryBrand500}` }}
              />
            </Col>
            <Col sm={6}>
              <InsideButton
                onClick={props.confirmButton.onClick}
                text={props.confirmButton.text}
                width="100%"
                type="button"
                style={{ backgroundColor: theme.primaryBrand500 }}
              />
            </Col>
          </>
        ) : (
          <>
            {props.isInsideButton ? (
              <>
                <Col sm={12}>
                  <InsideButton
                    onClick={props.confirmButton.onClick}
                    text={props.confirmButton.text}
                    width="100%"
                    type="button"
                    style={{ backgroundColor: theme.primaryBrand500 }}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col sm={12}>
                  <Button
                    onClick={props.confirmButton.onClick}
                    text={props.confirmButton.text}
                    type="button"
                    style={{ backgroundColor: theme.secondaryBrand500 }}
                  />
                </Col>
              </>
            )}
          </>
        )}
      </Row>
    </Row>
  );
}

export default DefaultModal;
