import styled from 'styled-components';
import { Button, Nav, Navbar } from 'react-bootstrap';
import metrics from 'utils/theme';
import { Link } from 'react-router-dom';

export const ContainerNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: ${metrics.spacings.small};
  align-items: center;

  @media (max-width: 700px) {
    justify-content: center;
  }
`;

interface CustomNavbarProps {
  typeProfile: string;
}

export const CustomNavbar = styled(Navbar)<CustomNavbarProps>`
  background-color: ${(props) =>
    props.typeProfile.includes('Aluno')
      ? props.theme.secondaryBrand500
      : props.theme.primaryBrand500} !important;
  justify-content: unset;

  @media (min-width: 992px) {
    height: 68px;
  }
`;

export const ContainerProfile = styled.div`
  display: flex;
  gap: ${metrics.spacings.small};
`;

export const ContainerLink = styled(Nav)`
  padding: ${metrics.spacings.smedium};

  @media (min-width: 992px) {
    display: none;
  }
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.white} !important;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CustomLinkSubmenu = styled.p`
  color: ${({ theme }) => theme.white} !important;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CustomLinkInside = styled(Link)`
  color: ${({ theme }) => theme.white};

  font-family: ${metrics.font.family[700]};
  font-size: ${metrics.font.size.medium};
  line-height: ${metrics.font.lineHeight.default};

  &:hover {
    transform: scale(1.1);
    color: ${({ theme }) => theme.white};
  }
  &:active {
    color: ${({ theme }) => theme.white};
  }
`;

interface ContainerSubmenuProps {
  isActive?: boolean;
}

export const ContainerSubmenu = styled.div<ContainerSubmenuProps>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const ListItemSubmenu = styled(Link)`
  color: ${({ theme }) => theme.white};

  &:hover {
    cursor: pointer;
  }
`;

export const ContainerTitle = styled.div`
  margin-left: ${metrics.spacings.smedium};

  @media (max-width: 700px) {
    display: none;
  }
`;

interface TitleProps {
  size?: 'small' | 'big';
}

export const Title = styled.h1<TitleProps>`
  color: ${({ theme }) => theme.white};
  font-family: ${metrics.font.family[700]};
  font-size: ${({ size }) =>
    size === 'small' ? metrics.font.size.slarge : metrics.font.size.large};
  line-height: ${metrics.font.lineHeight.default};
  margin-bottom: ${metrics.spacings.zero};
`;

export const Subtitle = styled.h1<TitleProps>`
  color: ${({ theme }) => theme.white};
  font-family: ${metrics.font.family[400]};
  font-size: ${metrics.font.size.medium};
  line-height: ${metrics.font.lineHeight.default};
  margin-bottom: ${metrics.spacings.zero};
`;

export const Toggle = styled(Button)`
  @media (min-width: 992px) {
    display: none;
  }

  border: 1px solid ${({ theme }) => theme.white};
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${({ theme }) => theme.white} !important;
    background-color: transparent !important;
    box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadow} !important;
    outline: 0px !important;
  }
`;

interface CollapseProps {
  show?: boolean;
}

export const Collapse = styled.div<CollapseProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;
