import { useTheme } from 'contexts/ThemeContext';
import { Container, Spinner } from './styles';

interface LoadingProps {
  status?: boolean;
}

const Loading = ({ status = false }: LoadingProps) => {
  const { theme } = useTheme();
  return (
    <Container status={status}>
      <Spinner color={theme.secondaryBrand500} background={theme.white} />
    </Container>
  );
};

export default Loading;
