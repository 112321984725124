const metrics = {
  font: {
    family: {
      400: 'Poppins-Regular',
      600: 'Poppins-SemiBold',
      700: 'Poppins-Bold',
    },
    size: {
      xsmall: '10px',
      small: '12px',
      smedium: '14px',
      medium: '16px',
      slarge: '18px',
      large: '24px',
      xlarge: '28px',
      xxlarge: '32px',
      xxxlarge: '40px',
    },
    lineHeight: {
      small: '16px',
      default: '24px',
    },
  },
  spacings: {
    zero: '0px',
    xxxsmall: '2px',
    xxsmall: '5px',
    xsmall: '8px',
    xssmall: '12px',
    small: '16px',
    ssmall: '20px',
    smedium: '24px',
    medium: '32px',
    slarge: '40px',
    large: '48px',
    xlarge: '56px',
    xxlarge: '64px',
  },
  buttons: {
    width: {
      default: '160px',
    },
    height: {
      default: '44px',
    },
  },
  borderRadius: {
    default: '8px',
    custom: '4px',
    photoRanking: '50px',
    controlMedia: '30px',
    scrollCustom: '20px',
  },
  padding: {
    default: '5px',
    medium: '10px',
  },
};

export default metrics;
