import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { api } from 'services/api';

interface TimerContextType {
  setId: (id: string) => void;
  setPathname: (path: string) => void;
  setIsActivity: (b: boolean) => void;
}

export const TimerContext = createContext({} as TimerContextType);

export const TimerProvider = ({ children }: { children: React.ReactNode }) => {
  const [id, setId] = useState('');
  const [pathname, setPathname] = useState('');
  const [repeat, setRepeat] = useState(true);
  const [isActivity, setIsActivity] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (!repeat) {
      setRepeat(true);
    }

    if (pathname === location.pathname && repeat && isActivity) {
      const timer = setTimeout(async () => {
        setRepeat(false);
        await api.get(`/atividade/${id}/atualizar-hora`);
      }, 60000);

      return () => clearTimeout(timer);
    }

    if (pathname === location.pathname && repeat && !isActivity) {
      const timer = setTimeout(async () => {
        setRepeat(false);
        await api.get(`/progresso/${id}/atualizar-hora`);
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [repeat, id, pathname, location.pathname, isActivity]);

  useEffect(() => {
    if (location.pathname !== pathname) {
      setIsActivity(false);
    }
  }, [pathname, pathname]);

  return (
    <TimerContext.Provider value={{ setId, setPathname, setIsActivity }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => {
  return useContext(TimerContext);
};
