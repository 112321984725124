import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import metrics from 'utils/theme';

interface CustomColSpaceProps {
  margin: string;
}

export const CustomColSpace = styled(Col)<CustomColSpaceProps>`
  margin-top: ${({ margin }) => margin};
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.tertiaryBrand500};

  font-size: ${metrics.font.size.smedium};
  font-family: ${metrics.font.family[700]};
  line-height: ${metrics.font.lineHeight.default};
`;

interface ButtonContainerProps {
  margin: string;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  margin-top: ${({ margin }) => margin};
`;

export const HelpArea = styled.div`
  text-align: center;
  margin-top: 130px;
`;
