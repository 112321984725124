import DefaultModal from 'components/DefaultModal';
import { useModal } from 'contexts/ModalContext';

interface HandleModalProps {
  title?: string;
  message?: string;
  icon?: 'success' | 'reprove' | 'error' | 'block' | 'noIcon';
  confirmButton?: {
    text: string;
    onClick?: () => void;
  };
  cancelButton?: {
    text: string;
    outline?: boolean;
    onClick?: () => void;
  };
  size?: 'sm' | 'lg' | 'xl';
  variant?: 'withOptions' | 'default';
  textCancelButton?: string;
  isInsideButton?: boolean;
  show?: boolean;
}

const useHandleModal = () => {
  const { setModal } = useModal();

  const handleModal = (modalProps: HandleModalProps) => {
    setModal({
      show: true,
      component: (
        <DefaultModal
          title={modalProps.title!}
          message={modalProps.message!}
          icon={modalProps.icon}
          cancelButton={{
            text: modalProps.cancelButton
              ? modalProps.cancelButton.text
              : modalProps.textCancelButton
                ? modalProps.textCancelButton
                : 'Não',
            outline: modalProps.cancelButton?.outline,
            onClick: () => {
              if (modalProps.cancelButton?.onClick) {
                modalProps.cancelButton.onClick();
                return setModal({
                  show: false,
                });
              }
              return setModal({
                show: false,
              });
            },
          }}
          confirmButton={{
            text: modalProps.confirmButton?.text || '',
            onClick: () => {
              if (modalProps.confirmButton?.onClick) {
                modalProps.confirmButton.onClick();
                return setModal({
                  show: false,
                });
              }
              return setModal({
                show: false,
              });
            },
          }}
          variant={modalProps.variant}
          isInsideButton={modalProps.isInsideButton}
        />
      ),
      size: modalProps.size ?? 'sm',
    });
    return;
  };

  return {
    handleModal,
  };
};

export default useHandleModal;
