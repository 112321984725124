import { Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonContainer, CustomColSpace } from 'utils/styleLogin';

import Button from 'components/Button/OutsideButton';
import Select from 'components/Inputs/Select';
import { useAuth } from 'contexts/AuthContext';
import { useTheme } from 'contexts/ThemeContext';
import useHandleModal from 'hooks/useHandleModal';
import { api } from 'services/api';
import metrics from 'utils/theme';
import { AuthDataResponse } from 'utils/types';
import { useModal } from 'contexts/ModalContext';
import {
  CallClassProps,
  CallSchoolProps,
  CallSchoolYearProps,
  HandleListProfileProps,
  IResponse,
  IResponseClass,
  IResponseSchool,
  IResponseSchoolYear,
  Localization,
  OnSumitProps,
} from 'utils/types';

export const useSetGeoLocation = () => {
  const setGeoLocation = async () => {
    const response = await fetch('https://geolocation-db.com/json/');
    const data: Localization = await response.json();
    const { IPv4, latitude, longitude } = data;
    await api.post('/localizacao', {
      ip: IPv4,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
    });
    await api.get('/acesso');
  };

  return { setGeoLocation };
};

export const useHandleProfile = () => {
  const handleProfile = async () => {
    try {
      const response: {
        profiles: {
          name: string;
          code: string;
          id: string;
          position: number;
        }[];
      } = await api.get('/profile');

      return response.profiles;
    } catch (error) {
      console.error(error);
    }
  };

  return { handleProfile };
};

export const useHandleListProfile = () => {
  const handleListProfile = async (props: HandleListProfileProps) => {
    const responseProfile: IResponse = await api.post(
      '/auth/login/listar-perfils',
      {
        emailOrCpf: props.emailOrCpf,
        type: props.type,
        password: props.password,
        sub: props.sub,
        socialLogin: props.socialLogin,
        instituicaoId: props.instituicaoId,
      }
    );

    return responseProfile.perfils;
  };

  return { handleListProfile };
};

export const useCallProfiles = () => {
  const { whiteLabel } = useTheme();
  const { setModal } = useModal();
  const { handleModal } = useHandleModal();
  const methods = useForm<{ profile: string }>();
  const { handleListProfile } = useHandleListProfile();
  const { handleProfile } = useHandleProfile();
  const { callSchool } = useCallSchool();
  const { callLoginAnalytica } = useCallLoginAnalytica();

  const callProfiles = async ({
    emailOrCpf,
    type,
    password = '',
    sub = '',
    socialLogin = false,
    signInF,
  }: HandleListProfileProps) => {
    try {
      const payload = {
        emailOrCpf,
        type,
        password,
        sub,
        socialLogin,
        instituicaoId: whiteLabel?.instituicaoId || '',
        signInF,
      };

      const responseListProfile = await handleListProfile(payload);
      let responseHandleProfile;
      if (responseListProfile !== undefined) {
        responseHandleProfile = await handleProfile();
      }

      const getOptions = responseHandleProfile
        ?.sort((a, b) => a.position - b.position)
        ?.map((profile) => {
          return {
            id: profile.id,
            value: profile.name,
          };
        });

      const filteredPerfils = getOptions!.filter((option) =>
        responseListProfile!.some((perfil) => option.value === perfil.name)
      );

      const profileAnalytica = [
        'Analytica',
        'Suporte',
        'Administrador',
        'Professor(a) Analytica',
        'Gestor Unidade',
        'Gestor Regional',
      ];

      const foundProfile = filteredPerfils.some((perfil) =>
        profileAnalytica.includes(perfil.value)
      );

      if (foundProfile) {
        return callLoginAnalytica(payload);
      }

      if (filteredPerfils.length > 1) {
        setModal({
          show: true,
          title: 'Selecione seu perfil',
          component: (
            <Row>
              <CustomColSpace md={12}>
                <FormProvider {...methods}>
                  <Form
                    onSubmit={methods.handleSubmit((data) =>
                      callSchool({
                        ...payload,
                        profile: data.profile || '',
                      })
                    )}
                  >
                    <Select
                      name="profile"
                      label="Perfil"
                      required
                      options={[
                        {
                          id: 0,
                          value: '',
                          name: 'Selecionar perfil',
                        },
                        ...(filteredPerfils ?? []),
                      ]}
                      error={!!methods.formState.errors.profile}
                    />
                    <ButtonContainer
                      margin={metrics.spacings.smedium}
                      className="d-grid gap-2"
                    >
                      <Button
                        type="submit"
                        text="Entrar"
                        style={{ background: whiteLabel?.corSecundaria }}
                      />
                    </ButtonContainer>
                  </Form>
                </FormProvider>
              </CustomColSpace>
            </Row>
          ),
        });

        return;
      }

      await callSchool({
        ...payload,
        profile: filteredPerfils[0].id,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      handleModal({
        title: 'Erro!',
        message: err?.data?.message,
        icon: 'block',
        confirmButton: { text: 'Voltar' },
      });
    }
  };

  return { callProfiles };
};

export const useCallLoginAnalytica = () => {
  const { setModal } = useModal();
  const { handleModal } = useHandleModal();
  const { signIn } = useAuth();
  const { setGeoLocation } = useSetGeoLocation();

  const callLoginAnalytica = async (props: HandleListProfileProps) => {
    try {
      setModal({ show: false });

      const response: AuthDataResponse = await api.post(
        '/auth/login-analytica',
        props
      );

      if (response.user) {
        if (!signIn && props.signInF) {
          props.signInF(response);
        }

        if (signIn) {
          signIn(response);
        }

        await setGeoLocation();
      }
      return;
    } catch (error: unknown) {
      const {
        data: { message },
      } = error as { data: { message: string } };

      handleModal({
        title: 'Error',
        message: message ?? 'Erro interno no sistema',
        icon: 'block',
        confirmButton: { text: 'Voltar' },
      });
    }
  };

  return { callLoginAnalytica };
};

export const useHandleSchool = () => {
  const handleSchool = async (props: HandleListProfileProps) => {
    const responseSchool: IResponseSchool = await api.post(
      '/auth/login/listar-escolas',
      {
        password: props.password,
        sub: props.sub,
        socialLogin: props.socialLogin,
        emailOrCpf: props.emailOrCpf,
        type: props.type,
        instituicaoId: props.instituicaoId,
      }
    );

    return responseSchool.escolas;
  };

  return { handleSchool };
};

export const useCallSchool = () => {
  const { whiteLabel } = useTheme();
  const { setModal } = useModal();
  const { handleModal } = useHandleModal();
  const methods = useForm<{ escolaId: string }>();
  const { handleSchool } = useHandleSchool();
  const { callSchoolYear } = useCallSchoolYear();

  const callSchool = async (props: CallSchoolProps) => {
    setModal({ show: false });

    try {
      const listSchool = await handleSchool(props);

      let allSchools: { id: string; value: string }[] = [];

      if (listSchool?.length) {
        allSchools = listSchool?.map(
          (escola: { id: string; name: string; instituicaoId: string }) => {
            return {
              id: escola.id,
              value: escola.name,
            };
          }
        );
      }

      if (allSchools.length > 1) {
        setModal({
          show: true,
          title: 'Selecione a escola',
          component: (
            <Row>
              <CustomColSpace md={12}>
                <FormProvider {...methods}>
                  <Form
                    onSubmit={methods.handleSubmit((data) =>
                      callSchoolYear({
                        ...props,
                        escolaId: data.escolaId || '',
                      })
                    )}
                  >
                    <Select
                      name="escolaId"
                      label="Escola"
                      required
                      options={[
                        {
                          id: 0,
                          value: '',
                          name: 'Selecionar escola',
                        },
                        ...(allSchools ?? []),
                      ]}
                      error={!!methods.formState.errors.escolaId}
                    />
                    <ButtonContainer
                      margin={metrics.spacings.smedium}
                      className="d-grid gap-2"
                    >
                      <Button
                        type="submit"
                        text="Entrar"
                        style={{ background: whiteLabel?.corSecundaria }}
                      />
                    </ButtonContainer>
                  </Form>
                </FormProvider>
              </CustomColSpace>
            </Row>
          ),
        });

        return;
      }

      await callSchoolYear({
        ...props,
        escolaId: allSchools[0].id,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      handleModal({
        title: 'Erro!',
        message: err?.data?.message,
        icon: 'block',
        confirmButton: { text: 'Voltar' },
      });
    }
  };

  return { callSchool };
};

export const useHandleSchoolYear = () => {
  const handleSchoolYear = async (props: CallSchoolYearProps) => {
    try {
      const responseSchoolYear: IResponseSchoolYear = await api.post(
        '/auth/login/listar-anoletivos',
        {
          emailOrCpf: props.emailOrCpf,
          type: props.type,
          instituicaoId: props.instituicaoId,
          escolaId: props.escolaId,
          profile: props.profile,
        }
      );

      return responseSchoolYear.anoletivos;
    } catch (error) {
      console.error(error);
    }
  };

  return { handleSchoolYear };
};

export const useCallSchoolYear = () => {
  const { whiteLabel } = useTheme();
  const { setModal } = useModal();
  const methods = useForm<{ anoletivoId: string }>();
  const { handleSchoolYear } = useHandleSchoolYear();
  const { callClass } = useCallClass();

  const callSchoolYear = async (props: CallSchoolYearProps) => {
    setModal({ show: false });

    try {
      const listSchoolYear = await handleSchoolYear(props);

      let allSchoolYear: { id: string; name: string }[] = [];

      if (listSchoolYear?.length) {
        allSchoolYear = listSchoolYear?.map(
          (anoletivo: { id: string; name: string }) => {
            return {
              id: anoletivo.id,
              name: anoletivo.name,
            };
          }
        );
      }

      if (allSchoolYear.length > 1) {
        setModal({
          show: true,
          title: 'Selecione o ano letivo',
          component: (
            <Row>
              <CustomColSpace md={12}>
                <FormProvider {...methods}>
                  <Form
                    onSubmit={methods.handleSubmit((data) =>
                      callClass({
                        ...props,
                        anoletivoId: data.anoletivoId || '',
                      })
                    )}
                  >
                    <Select
                      name="anoletivoId"
                      label="Ano letivo"
                      required
                      options={[
                        {
                          id: 0,
                          value: '',
                          name: 'Selecionar ano letivo',
                        },
                        ...(allSchoolYear ?? []),
                      ]}
                      error={!!methods.formState.errors.anoletivoId}
                    />
                    <ButtonContainer
                      margin={metrics.spacings.smedium}
                      className="d-grid gap-2"
                    >
                      <Button
                        type="submit"
                        text="Entrar"
                        style={{ background: whiteLabel?.corSecundaria }}
                      />
                    </ButtonContainer>
                  </Form>
                </FormProvider>
              </CustomColSpace>
            </Row>
          ),
        });

        return;
      }

      await callClass({
        ...props,
        anoletivoId: allSchoolYear[0].id,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return { callSchoolYear };
};

export const useHandleClass = () => {
  const handleClass = async (props: CallClassProps) => {
    try {
      const responseClass: IResponseClass = await api.post(
        '/auth/login/listar-turmas',
        {
          emailOrCpf: props.emailOrCpf,
          type: props.type,
          escolaId: props.escolaId,
          anoletivoId: props.anoletivoId,
          instituicaoId: props.instituicaoId,
        }
      );

      return responseClass.turmas;
    } catch (error) {
      console.error(error);
    }
  };

  return { handleClass };
};

export const useOnSubmit = () => {
  const { setModal } = useModal();
  const { handleModal } = useHandleModal();
  const { signIn } = useAuth();
  const { setGeoLocation } = useSetGeoLocation();

  const onSubmit = async (props: OnSumitProps) => {
    try {
      setModal({ show: false });

      const response: AuthDataResponse = await api.post('/auth/login', props);

      if (response.user) {
        if (!signIn && props.signInF) {
          props.signInF(response);
        }

        if (signIn) {
          signIn(response);
        }

        await setGeoLocation();
      }
    } catch (error: unknown) {
      const {
        data: { message },
      } = error as { data: { message: string } };

      handleModal({
        title: 'Error',
        message: message ?? 'Erro interno no sistema',
        icon: 'block',
        confirmButton: { text: 'Voltar' },
      });
    }
  };

  return { onSubmit };
};

export const useCallClass = () => {
  const { whiteLabel } = useTheme();
  const { setModal } = useModal();
  const methods = useForm<{ turmaId: string }>();
  const { handleClass } = useHandleClass();
  const { onSubmit } = useOnSubmit();

  const callClass = async (props: CallClassProps) => {
    setModal({ show: false });

    try {
      const listClass = await handleClass(props);

      let allClass: { id: string; name: string }[] = [];

      if (listClass?.length) {
        allClass = listClass?.map((turma: { id: string; name: string }) => {
          return {
            id: turma.id,
            name: turma.name,
          };
        });
      }

      if (allClass.length > 1) {
        setModal({
          show: true,
          title: 'Selecione a turma',
          component: (
            <Row>
              <CustomColSpace md={12}>
                <FormProvider {...methods}>
                  <Form
                    onSubmit={methods.handleSubmit((data) =>
                      onSubmit({
                        ...props,
                        turmaId: data.turmaId || '',
                      })
                    )}
                  >
                    <Select
                      name="turmaId"
                      label="Turma"
                      required
                      options={[
                        {
                          id: 0,
                          value: '',
                          name: 'Selecionar turma',
                        },
                        ...(allClass ?? []),
                      ]}
                      error={!!methods.formState.errors.turmaId}
                    />
                    <ButtonContainer
                      margin={metrics.spacings.smedium}
                      className="d-grid gap-2"
                    >
                      <Button
                        type="submit"
                        text="Entrar"
                        style={{ background: whiteLabel?.corSecundaria }}
                      />
                    </ButtonContainer>
                  </Form>
                </FormProvider>
              </CustomColSpace>
            </Row>
          ),
        });

        return;
      }

      await onSubmit({
        ...props,
        turmaId: allClass[0].id,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return { callClass };
};
