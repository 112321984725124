enum PROFILES {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  RESPONSIBLE = 'RESPONSIBLE',
  ADMIN_REGIONAL = 'ADMIN_REGIONAL',
  ADMIN = 'ADMIN',
  GESTOR_REGIONAL = 'GESTOR_REGIONAL',
  ANALYTICA = 'ANALYTICA',
  TEACHER_ANALYTICA = 'TEACHER_ANALYTICA',
  SUPORT = 'SUPORT',
}

export default PROFILES;
