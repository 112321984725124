import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import metrics from 'utils/theme';
import colors from 'utils/colors';

interface CustomButtonProps {
  outline?: boolean;
  customGoogleButton?: boolean;
}

export const CustomButton = styled(Button)<CustomButtonProps>`
  font-family: ${metrics.font.family[700]};
  font-size: ${metrics.font.size.medium};
  line-height: ${metrics.font.lineHeight.default};
  width: 100%;
  height: 48px;
  border: ${({ outline, customGoogleButton, theme }) =>
    customGoogleButton
      ? `1px solid ${colors.tertiaryBrand500}`
      : outline
        ? `1px solid ${theme.secondaryBrand500}`
        : 'none'};
  border-radius: 8px;
  transition: 0.5s;
  color: ${({ outline, customGoogleButton, theme }) =>
    customGoogleButton
      ? colors.tertiaryBrand500
      : outline
        ? theme.secondaryBrand500
        : theme.white};
  background: ${({ outline, customGoogleButton, theme }) =>
    outline || customGoogleButton ? 'transparent' : theme.secondaryBrand500};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme, customGoogleButton }) =>
      customGoogleButton ? colors.tertiaryBrand500 : theme.white} !important;
    background: ${({ theme, customGoogleButton }) =>
      customGoogleButton ? 'transparent' : theme.secondaryBrand500} !important;
    border: ${({ outline, customGoogleButton, theme }) =>
      customGoogleButton
        ? `1px solid ${colors.tertiaryBrand500}`
        : outline
          ? `1px solid ${theme.secondaryBrand500}`
          : 'none'} !important;
    box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadow} !important;
    outline: 0px !important;
  }
`;
