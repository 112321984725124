import styled from 'styled-components';
import metrics from 'utils/theme';

export const TitleModal = styled.p`
  font-family: ${metrics.font.family[700]};
  font-size: ${metrics.font.size.large};
  margin-bottom: ${metrics.spacings.medium};
  margin-top: -${metrics.spacings.medium};
  line-height: ${metrics.font.lineHeight.default};
  text-align: center;
`;

export const IconContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 92px;
  margin: ${metrics.spacings.medium} 0;
  background: ${({ theme }) => theme.gray};
  border-radius: 50%;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const MessageWrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  text-align: center;
`;
