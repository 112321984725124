import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import metrics from 'utils/theme';

export const ContentWrapper = styled.div`
  height: ${metrics.spacings.large};
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadow};
  border: none;
  border-radius: ${metrics.spacings.xsmall};
`;

export const CustomDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
`;

export const CustomDropdownMenu = styled(Dropdown.Menu)`
  @media (max-width: 992px) {
    display: none;
  }
  width: 100%;
`;

export const UserInformation = styled.div`
  padding-left: 18px;
`;

export const Togglebutton = styled(Dropdown.Toggle)`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.darkblue};
  border: none;

  &:hover,
  &:active {
    background: ${({ theme }) => theme.gray};
    color: ${({ theme }) => theme.darkblue};
  }
`;

export const LogoutButton = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  justify-content: start;

  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.error500};

  width: 100%;
  padding: ${metrics.spacings.xsmall} ${metrics.spacings.small}
    ${metrics.spacings.xsmall} ${metrics.spacings.small};
  gap: ${metrics.spacings.xsmall};

  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.gray};
  }
`;

export const Button = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  justify-content: start;

  background-color: ${({ theme }) => theme.white};

  width: 100%;
  padding: ${metrics.spacings.xsmall} ${metrics.spacings.small}
    ${metrics.spacings.xsmall} ${metrics.spacings.small};
  gap: ${metrics.spacings.xsmall};

  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.gray};
  }
`;

export const ProfileDataWrapper = styled.div`
  display: flex;
  align-items: start;

  flex-direction: column;

  padding-top: ${metrics.spacings.xxsmall};
  height: ${metrics.spacings.large};

  @media (max-width: 450px) {
    max-width: 12ch;
    justify-content: center;
  }
`;

export const ProfilePicWrapper = styled.div`
  margin: 0px ${metrics.spacings.xsmall} 0px ${metrics.spacings.xsmall};
  border-radius: ${metrics.spacings.xsmall};
`;

interface UsernameProps {
  margin?: boolean;
}

export const Username = styled.p<UsernameProps>`
  ${({ margin }) => margin && `margin-top: ${metrics.spacings.xxsmall};`}
  font-weight: ${metrics.font.family[600]};
  font-size: ${metrics.font.size.slarge};
  color: ${({ theme }) => theme.darkblue300};
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 500px) {
    font-size: ${metrics.font.size.small};
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const UsernameRA = styled.div`
  font-weight: ${metrics.font.family[600]};
  font-size: ${metrics.font.size.small};
  line-height: ${metrics.font.lineHeight.small};
  color: ${({ theme }) => theme.darkblue300};
  @media (max-width: 500px) {
    font-size: ${metrics.font.size.xsmall};
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Footer = styled.div`
  margin-top: ${metrics.spacings.small};
`;
