import { Dropdown, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { MdDelete } from 'react-icons/md';
import metrics from 'utils/theme';

export const ContentWrapper = styled.div`
  height: ${metrics.spacings.large};
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadow};
  border: none;
  border-radius: ${metrics.spacings.xsmall};
`;

export const CustomDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
`;

export const CustomDropdownMenu = styled(Dropdown.Menu)`
  width: 300px;
  overflow: auto;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const Button = styled(Dropdown.Item)`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  background-color: ${({ theme }) => theme.white};

  width: 88%;
  height: 100%;

  padding: ${metrics.spacings.xsmall} ${metrics.spacings.small}
    ${metrics.spacings.xsmall} ${metrics.spacings.small};
  gap: ${metrics.spacings.xsmall};

  border: none;
`;

export const Togglebutton = styled(Dropdown.Toggle)`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.darkblue};
  border: none;

  &:hover,
  &:active {
    background: ${({ theme }) => theme.gray};
    color: ${({ theme }) => theme.darkblue};
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 5px;
  height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.darkgray100};
`;

export const Footer = styled.div`
  margin-top: ${metrics.spacings.small};
`;

export const NoNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RowAlert = styled(Row)`
  align-items: left;
  justify-content: left;
  font-family: ${metrics.font.family[600]};
  font-size: 13px;
`;

export const RowAlertDate = styled(Row)`
  font-size: ${metrics.font.size.small};
`;

export const MdDeleteIcon = styled(MdDelete)`
  cursor: pointer;
  &:hover,
  &:active {
    color: ${({ theme }) => theme.darkblue};
  }
`;

export const AlertNotification = styled.div`
  margin-top: -10px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 22px;
  left: 26px;
  z-index: 1;
  background-color: ${({ theme }) => theme.primaryBrand500};
  border-radius: 50%;
`;

export const ButtonReaded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.darkgray200};
`;
