import styled from 'styled-components';
import metrics from 'utils/theme';
import { Link } from 'react-router-dom';

export const SideBar = styled.div`
  background-color: ${({ theme }) => theme.tertiaryBrand500};
  position: sticky;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

interface ListItemProps {
  isActive: boolean;
  disabled?: boolean;
  isSelected?: boolean;
}

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${metrics.spacings.xsmall} 0px ${metrics.spacings.xsmall} 0px;

  transition: transform 0.4s;

  background-color: ${({ isSelected, isActive, theme }) =>
    isActive || isSelected ? `${theme.primaryBrand500}` : null};

  border-right: ${({ isSelected, isActive, theme }) =>
    `${metrics.spacings.xxsmall} solid ${
      isActive || isSelected ? theme.secondaryBrand500 : theme.tertiaryBrand500
    }`};

  &:hover {
    background-color: ${({ theme }) => theme.primaryBrand500};
    border-right: ${metrics.spacings.xxsmall} solid
      ${({ theme }) => theme.secondaryBrand500};
    cursor: pointer;
  }
`;

export const ContainerSubmenu = styled.div<ListItemProps>`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
`;

export const ListItemSubmenu = styled.li<ListItemProps>`
  display: flex;
  width: 100%;
  padding: ${metrics.spacings.xsmall} 0px ${metrics.spacings.xsmall} 0px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  transition: transform 0.4s;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.secondaryBrand500};
    border-bottom: 1px solid darkgray;
    border-top: 1px solid darkgray;
  }
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.secondaryBrand500 : null};
`;

interface MenuProps {
  disabled?: boolean;
}

export const LinkContainer = styled.div<MenuProps>`
  width: 60%;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  padding-left: ${metrics.spacings.medium};
`;

export const Customlink = styled(Link)`
  color: ${({ theme }) => theme.white};

  font-family: ${metrics.font.family[700]};
  font-size: ${metrics.font.size.medium};
  line-height: ${metrics.font.lineHeight.default};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.white};
  }
`;

export const CustomLinkThree = styled.div`
  color: ${({ theme }) => theme.white};

  font-family: ${metrics.font.family[700]};
  font-size: ${metrics.font.size.medium};
  line-height: ${metrics.font.lineHeight.default};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.white};
  }
`;

export const CustomLinkSubmenu = styled(Link)<MenuProps>`
  width: 100%;
  padding-left: ${metrics.spacings.medium};
  color: ${({ theme }) => theme.white};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  font-family: ${metrics.font.family[700]};
  font-size: ${metrics.font.size.medium};
  line-height: ${metrics.font.lineHeight.default};


  &:hover {
    transform: scale(1.1);
    color: ${({ theme }) => theme.white}};
  };
  &:active {
    color: ${({ theme }) => theme.white};
  };
`;

interface TitleProps {
  margin?: string;
  isActive?: boolean;
}

export const TitleWrapper = styled.div<TitleProps>`
  display: flex;
  align-items: center;

  padding-top: ${metrics.spacings.xxsmall};

  margin-right: ${({ margin }) => margin};

  @media (min-width: 1600px) {
    gap: ${metrics.spacings.small};
  }
`;

export const IconDiv = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
`;

export const Title = styled.h4<TitleProps>`
  display: flex;
  justify-content: space-between;
  min-width: 100%;

  margin-top: ${metrics.spacings.xxsmall};

  font-family: ${({ isActive }) =>
    isActive ? ` ${metrics.font.family[700]}` : `${metrics.font.family[400]}`};
  font-size: ${metrics.font.size.medium};
  line-height: ${metrics.font.lineHeight.default};

  margin-left: ${({ margin }) => margin};
`;
export const TitleSubmenu = styled.h4<TitleProps>`
  display: flex;
  justify-content: space-between;
  min-width: 100%;

  margin-top: ${metrics.spacings.xxsmall};

  font-family: ${({ isActive }) =>
    isActive ? ` ${metrics.font.family[700]}` : `${metrics.font.family[400]}`};
  font-size: ${metrics.font.size.smedium};
  line-height: ${metrics.font.lineHeight.default};

  margin-left: ${({ margin }) => margin};
`;

export const Arrow = styled.div<ListItemProps>`
  display: flex;
  align-items: center;
  margin-right: ${metrics.spacings.medium};

  transform: ${({ isActive }) =>
    !isActive ? `rotate(180deg)` : `rotate(0deg)`};
`;

export const ContainerLogo = styled.div`
  display: block;
  padding: ${metrics.spacings.small} 0px ${metrics.spacings.xsmall} 0px;

  background-color: ${({ theme }) => theme.tertiaryBrand500};
  position: sticky;
  top: 0;
  z-index: 10;
`;
