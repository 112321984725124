import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import { ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import metrics from 'utils/theme';

interface StyledModalProps {
  boldFont?: boolean;
}

export const CustomModal = styled(Modal)`
  padding: ${metrics.spacings.small};
  z-index: 9999;
`;

export const CustomModalHeader = styled(ModalHeader)`
  border-bottom: none;
`;

export const CustomModalTitle = styled(ModalTitle)<StyledModalProps>`
  font-family: ${({ boldFont }) =>
    boldFont ? metrics.font.family[700] : metrics.font.family[400]};
`;

export const CustomModalBody = styled(ModalBody)`
  font-family: ${metrics.font.family[400]};
  font-size: ${metrics.font.size.medium};
  line-height: ${metrics.font.lineHeight.small};
`;

export const ModalFooter = styled.div`
  padding: ${metrics.spacings.small} ${metrics.spacings.small}
    ${metrics.spacings.medium} ${metrics.spacings.small};
`;

export const Message = styled.p`
  display: flex;

  justify-content: center;
  align-items: center;

  text-align: center;
`;
