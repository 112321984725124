import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import metrics from 'utils/theme';

interface CustomButtonProps {
  outline?: boolean;
  width?: string;
  minWidth?: string;
  borderRadius?: string;
  background: string;
  white: string;
  secondary: string;
}

interface CustonJustify {
  justifyStyle?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

export const CustomButton = styled(Button)<CustomButtonProps>`
  font-family: ${metrics.font.family[700]};
  font-size: ${metrics.font.size.smedium};
  text-align: center;
  line-height: ${metrics.font.lineHeight.default};
  height: ${metrics.buttons.height.default};
  margin-bottom: ${metrics.spacings.xsmall};
  border: ${({ outline, background }) =>
    outline ? `1px solid ${background}` : 'none'};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '')};
  white-space: nowrap;
  transition: 0.5s;
  color: ${({ outline, background, white }) =>
    outline ? background : white} !important;
  background: ${({ outline, background }) =>
    outline ? 'transparent' : background};
  width: ${({ width }) => (width ? width : '')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '')};
  &.btn-inside:hover,
  &.btn-inside:active,
  &.btn-inside:focus {
    color: ${({ white }) => white} !important;
    background: ${({ background }) => background};
    border: ${({ outline, background }) =>
      outline ? `1px solid ${background}` : 'none'};
    box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadow} !important;
    outline: 0px !important;
    opacity: 0.95;
  }
  &.btn-inside:disabled {
    color: ${({ white }) => white} !important;
    background: ${({ background }) => background} !important;
    border: ${({ outline, background }) =>
      outline ? `1px solid ${background}` : 'none'};
    outline: 0px !important;
    opacity: 0.8;
  }
  &.A_VENCER {
    border: 1px solid ${({ secondary }) => secondary} !important;
    color: ${({ secondary }) => secondary} !important;
  }
`;

export const Container = styled.div<CustonJustify>`
  display: flex;
  align-items: center;
  margin-left: ${({ justifyStyle }) =>
    justifyStyle !== 'center' ? `-${metrics.spacings.medium}` : '0px'};
  justify-content: ${({ justifyStyle }) => justifyStyle};
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: ${metrics.spacings.xxsmall};
  height: 100%;
  font-size: 18px;
`;
