import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Contexts from 'contexts';
import Paths from 'routes/index.routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/global.css';
import 'chart.js/auto';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <BrowserRouter>
        <Contexts>
          <Paths />
        </Contexts>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
