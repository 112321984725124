import { createContext, useContext, useEffect } from 'react';
import { api } from 'services/api';
import { useSpinner } from './SpinnerContext';

export const InterceptorContext = createContext({});

export const InterceptorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setLoading } = useSpinner();
  useEffect(() => {
    api.interceptors.request.use((request) => {
      if (
        request.url?.includes('/notificacao?limite=5') ||
        request.url?.includes('/acesso/tempo')
      ) {
        return request;
      }

      setLoading(true);
      return request;
    });
  }, [api]);

  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const checkMessage = response as any;

        if (checkMessage?.message === 'Mensagens encontradas com sucesso') {
          return response;
        }

        setLoading(false);
        return response;
      },
      (error: Error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, [setLoading]);

  return (
    <InterceptorContext.Provider value={{}}>
      {children}
    </InterceptorContext.Provider>
  );
};

export const useInterceptor = () => {
  return useContext(InterceptorContext);
};
