import { createContext, useContext, useState } from 'react';

interface PaginationContextType {
  setPage: (page: number) => void;
  page: number;
  setLastPage: (page: number) => void;
  lastPage: number;
  LIMITPERPAGE: number;
}

export const PaginationContext = createContext({} as PaginationContextType);

export const PaginationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const LIMITPERPAGE = 10;

  return (
    <PaginationContext.Provider
      value={{
        setPage,
        page,
        setLastPage,
        lastPage,
        LIMITPERPAGE,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => {
  return useContext(PaginationContext);
};
