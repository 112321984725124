import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import metrics from 'utils/theme';

export const Group = styled(Form.Group)`
  margin-bottom: ${metrics.spacings.xsmall};
`;

export const Label = styled(Form.Label)`
  color: ${({ theme }) => theme.tertiaryBrand500};

  font-family: ${metrics.font.family[400]};
  font-size: ${metrics.font.size.small};
  line-height: ${metrics.font.lineHeight.small};

  margin-bottom: 0px;
`;

interface SelectControlProps {
  error?: boolean;
  noPadding?: boolean;
}

export const SelectControl = styled(Form.Select)<SelectControlProps>`
  color: ${({ theme }) => theme.tertiaryBrand500};
  padding: ${(noPadding) =>
    noPadding
      ? 'none'
      : `${metrics.spacings.xsmall} ${metrics.spacings.small}`};
  border: 1px solid ${({ theme }) => theme.tertiaryBrand500};
  border-radius: 8px;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
