import { Col, Row } from 'react-bootstrap';
import Aside from 'components/Aside';
import Header from 'components/Header';
import backgroundImg from 'assets/img/login-screen.png';

import {
  CustomContainer,
  CustomContainerDashboard,
  ImageBackground,
  CustomCol,
  ContainerAside,
  ContainerHeader,
  CustomColOverflow,
  Imagem,
} from './style';
import { useEffect } from 'react';
import { useTheme } from 'contexts/ThemeContext';

interface WrapperProps {
  dashboard?: boolean;
  children: React.ReactNode;
}

function Wrapper({ children, dashboard = false }: WrapperProps) {
  const { whiteLabel } = useTheme();
  const pathname = window.location.pathname;
  const childrenScroll = document.getElementById('childrenScroll');

  useEffect(() => {
    if (childrenScroll) {
      childrenScroll.scrollBy(0, -childrenScroll?.scrollHeight);
    }
  }, [pathname, childrenScroll]);

  if (dashboard) {
    return (
      <CustomContainerDashboard fluid>
        <Row>
          <ContainerAside lg={2} className="d-md-none d-lg-block">
            <Aside />
          </ContainerAside>
          <CustomColOverflow lg={10} className="vh-100" id="childrenScroll">
            <Row>
              <ContainerHeader md={12}>
                <Header />
              </ContainerHeader>
              <Col md={12} style={{ paddingBottom: '25px' }}>
                <main>{children}</main>
              </Col>
            </Row>
          </CustomColOverflow>
        </Row>
      </CustomContainerDashboard>
    );
  }

  return (
    <CustomContainer fluid>
      {!pathname.includes('/404') ? (
        <Row>
          <ImageBackground lg={6} className="d-none d-lg-block">
            <Imagem
              src={whiteLabel.urlImagemLogin ?? backgroundImg}
              className="img-fluid"
              alt={`${whiteLabel.nomeFantasia ?? 'Analytica Ensino'}`}
            />
          </ImageBackground>
          <CustomCol lg={6}>
            <main>{children}</main>
          </CustomCol>
        </Row>
      ) : (
        <main>{children}</main>
      )}
    </CustomContainer>
  );
}

export default Wrapper;
